// Set your brand colors.
$primary: #00d0bf;
$background: #ffffff;
$dark: #00043c;

$text: #00043c;
$title-color: #00043c;

$link: $primary;

$tabs-link-active-border-bottom-color: #00d0bf;
$tabs-link-active-color: #00d0bf;
$tabs-link-color: #00043c;
$tabs-link-hover-border-bottom-color: #00043c;
$tabs-link-hover-color: #00043c;

// Update Bulma's global variables.
$family-sans-serif: 'Nunito Sans', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial',
    sans-serif;
$family-primary: $family-sans-serif;
$family-secondary: 'Poppins', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial',
    sans-serif;
$fullhd-enabled: false;
