@import 'variables';

html {
    overflow-y: auto;
}

.title {
    line-height: 1.5;
    font-family: $family-secondary;
    font-weight: 700;
}

.subtitle {
    opacity: 0.75;
    line-height: 1.5;
}

.form-padding {
    padding: 1.5rem;
    background: #fafafa;
}

.question {
    padding: 0.5rem;
    background: #e0e0e0;
}

.input {
    border-color: #cccccc;
    box-shadow: none;
}

.input:focus {
    border-width: 2px;
    outline: #3273dc;
    box-shadow: none !important;
}

.has-cursor-pointer {
    cursor: pointer;
}
.drag-handle {
    cursor: move;
}

//color picker
.color {
    width: 16px;
    height: 16px;
    border-radius: 8px;
}
.swatch {
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}
.popover {
    position: absolute;
    z-index: 2;
}
.popover .block-picker {
    transform: translatex(-50%);
    margin-left: 12px;
    margin-top: 5px;
    box-shadow: 1px 5px 38px 0px rgba(93, 94, 95, 0.35) !important;
    z-index: 3;
}
.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.color-picker {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 15px;
}
.delete-trash {
    right: 6px;
    top: 8px;
    position: absolute;
}
.questions .question {
    position: relative;
}
.icon-svg {
    width: 24px;
    height: 24px;
}
.back-icon {
    display: flex;
    color: #515151 !important;
}
.back-icon span:first-child {
    margin-right: 5px;
}
.add-question {
    border: 2px dashed #9e9e9e;
    cursor: pointer;
    padding: 5px 0;
    margin-bottom: 15px;
    display: flex;
    color: #9e9e9e;
    justify-content: center;
}
.single-standup {
    border: 1px solid #e0e0e0;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.08);
}
.participants-image {
    display: inline-block;
    padding-right: 5px;
    padding-bottom: 5px;
}
.channel-tag {
    margin-right: 5px !important;
    margin-bottom: 5px !important;
}
.response-answer {
    padding-left: 10px;
    white-space: pre-line;
}
.single-report {
    border: 1px solid #e0e0e0;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.08);
}
.is-vertical-center {
    display: flex;
    align-items: center;
}
.active-status {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}
.common-date::before {
    content: '';
    width: 100%;
    position: absolute;
    height: 2px;
    background: #eeeeee;
    top: 50%;
    left: 0;
}
.common-date {
    position: relative;
}
/* Days choose */
.date-display {
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 0 15px;
}
.days-checkbox {
    line-height: 0rem;
}
.days-checkbox [type='checkbox'] {
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: relative;
}
.days-checkbox label {
    display: flex;
    width: 40px;
    height: 40px;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    margin-right: 5px;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #9e9e9e;
}
.days-checkbox [type='checkbox']:checked + label {
    background: #00d1b2;
}
.days-checkbox [type='checkbox']:checked + label:after {
    content: '\2714';
    position: absolute;
    right: 3px;
    top: 2px;
    font-size: 14px;
    color: #ffffff;
    width: 18px;
    height: 18px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    background: #0ca991;
    border-radius: 50%;
}
/* Custom checkbox */
.custom-checkbox-wrapper {
    display: flex;
    align-items: center;
}
.custom-checkbox [type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.custom-checkbox label {
    cursor: pointer;
    text-indent: -9999px;
    width: 32px;
    height: 16px;
    background: grey;
    display: inline-block;
    border-radius: 16px;
    position: relative;
    margin-right: 7px;
}

.custom-checkbox label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 14px;
    transition: 0.3s;
}

.custom-checkbox input:checked + label {
    background: #00d1b2;
}

.custom-checkbox input:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
}
.pricing-plan {
    border: 1px solid #e0e0e0;
    padding: 15px;
    box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.08);
}
.plan-header {
    text-transform: uppercase;
    font-weight: 600;
}
.plan-item {
    margin-bottom: 5px;
}
.plan-item:before {
    content: '\2714';
    color: #00d1b2;
    margin-right: 5px;
}

.has-text-strike {
    text-decoration: line-through;
}

.bg-image {
    background-size: cover;
    background-repeat: no-repeat;
}
.accordion-header {
    background: rgba(0, 0, 0, 0.2);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

article.accordion {
    margin-bottom: 15px;
}

.accordion-body {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
}
.faq-icon {
    width: 18px;
    height: 18px;
}
.export-header .title {
    display: flex;
    justify-content: center;
    align-items: center;
}
.export-header {
    background: #eeeeee;
    padding: 10px 5px;
}
.export-form {
    background: #f5f5f5;
    padding: 10px;
}
.navbar {
    border-bottom: 1px solid #efefef;
}
.navbar-item.is-active {
    font-weight: 600;
}
.text-secondary {
    opacity: 0.75;
}
.link a {
    display: block;
    opacity: 0.75;
    color: #fff;
    transition: all ease 0.2s;
    margin-bottom: 10px;
    &:hover {
        opacity: 1;
    }
}

.footer-icon {
    margin-right: 10px;
    transition: all ease 0.2s;

    opacity: 0.75;
    &:hover {
        opacity: 1;
    }
}
.plan-card {
    border-radius: 0.25rem;
    transition: all ease 0.2s;
    cursor: default;
    &.popular {
        transform: scale(1.1);
        box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    }
    &.plan-card--padded {
        padding: 20px 30px;
    }
    &.plan-card--subtle {
        border: 1px solid #ccc;
    }
}
.template-item {
    background: #fff;
    margin-bottom: 15px;
    border-radius: 3px;
    border: 1px solid #eee;
    overflow: auto;
    transition: all ease 0.2s;
    .template-header {
        color: #fff;
        background: rgb(0, 4, 60);
        padding: 10px 15px;
    }
    &:hover {
        box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3),
            0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    }
}
h5.header {
    color: #525252;
    font-weight: 600;
    font-size: 18px;
}
.ques {
    border-radius: 3px;
    padding: 10px 15px;
}
.ques:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
}
.icon-svg {
    vertical-align: middle;
}

// Sign in with Slack button.
.navbar-item img {
    max-height: 2.5rem;
}

// Responsive video player.
.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    min-height: 400px;
}
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

// Legal.
.has-contract-numbering {
    counter-reset: section;
    h2:before {
        counter-increment: section;
        content: counter(section) '.';
        margin: 0 0.5em 0 0;
    }
    ol {
        margin-left: 0;
        counter-reset: clause;
        list-style-type: none;
        list-style-position: outside;
        li {
            padding-left: 4em;
            text-indent: -2.5em;
            counter-increment: clause;
            &:before {
                content: counter(section) '.' counters(clause, '.') '.';
                margin: 0 0.5em 0 0;
            }
            li {
                padding-left: 0;
                text-indent: 0;
            }
        }
    }
}

ul {
    &.has-list-checkmarks,
    &.has-list-crosses {
        margin-left: 0;
        list-style-type: none;
        list-style-position: outside;
        li {
            padding-left: 3em;
            text-indent: -1.8em;
            &:before {
                content: '';
                display: inline-block;
                height: 1em;
                width: 1em;
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 0.5em;
            }
        }
    }
}

.has-list-checkmarks {
    li {
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%2300d0bf' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
        }
    }
}

.has-list-crosses {
    li {
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' class='svg-inline--fa fa-times fa-w-11' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3Cpath fill='%23d61f69' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'%3E%3C/path%3E%3C/svg%3E");
        }
    }
}

.has-contract {
    &.content {
        ol {
            &:not([type]) {
                list-style-type: none;
            }

            li + li {
                margin-top: 1em;
            }
        }

        ul {
            &:not([type]) {
                list-style-type: none;
            }

            li + li {
                margin-top: 1em;
            }
        }

        h2:not(:first-child) {
            margin-top: 2em;
        }
        h3:not(:first-child) {
            margin-top: 2.5em;
        }
    }
}

// Avatars.
.is-avatar-danger {
    border: 4px solid $danger;
}
.is-avatar-success {
    border: 4px solid $success;
}
